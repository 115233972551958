import fetch from "./xhr/index";

/**
 * 首页报表接口
 * @param
 */
export const getList = param => fetch("GET", "/sys/sysFile/list", param);

/**
 * 新建修改类目
 * @param
 */
export const createFileCategory = (param) => fetch("POST", `/sys/sysFile/createFileCategory`, param);

/**
 * 上传文件
 * @param
 */
export const uploadFile = (param) => fetch("UPLOADMUL", `/sys/sysFile/upload`, param);


/**
 * 删除文件
 * @param
 */
export const delfile = param => fetch("GET", "/sys/sysFile/del", param);

/**
 * 设置是否公开
 * @param
 */
export const showflag = param => fetch("GET", "/sys/sysFile/isShow", param);

/**
 * 文件夹列表
 * @param
 */
export const folderList = param => fetch("GET", "/sys/sysFile/folderList", param);

/**
 * 移动文件和文件夹
 * @param
 */
export const moveFile = (param) => fetch("GET", `/sys/sysFile/moveFile`, param);

