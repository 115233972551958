var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"cloud-container",attrs:{"element-loading-text":"数据较多，拼命加载中..."}},[_c('el-tabs',{staticClass:"filetab",on:{"tab-click":_vm.handleClick},model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},[_c('el-tab-pane',{attrs:{"label":"所有文档","name":"first"}},[_c('breadcrumb',{attrs:{"BreadcrumbData":_vm.BreadcrumbData},on:{"return":_vm.returnUrl}}),_c('div',{staticClass:"common-screen-container"},[_c('div',{staticClass:"common-input-container"},[_c('span',[_vm._v("文档名称/关键词:")]),_c('el-input',{staticClass:"common-screen-input",attrs:{"placeholder":"请输入内容","clearable":""},model:{value:(_vm.params.fileName),callback:function ($$v) {_vm.$set(_vm.params, "fileName", $$v)},expression:"params.fileName"}})],1),_c('div',{staticClass:"common-input-container"},[_c('span',[_vm._v("创建人:")]),_c('el-input',{staticClass:"common-screen-input",attrs:{"placeholder":"请输入内容","clearable":""},model:{value:(_vm.params.createName),callback:function ($$v) {_vm.$set(_vm.params, "createName", $$v)},expression:"params.createName"}})],1),_c('br'),_c('div',{staticClass:"common-input-container"},[_c('common-date',{ref:"commonReset",on:{"commonGetDate":_vm.commonGetDate}})],1),_c('el-button',{staticClass:"common-screen-btn",attrs:{"type":"primary"},on:{"click":function () {
              _vm.getCloudList();
            }}},[_vm._v("查 询")]),_c('el-button',{staticClass:"common-screen-btn",attrs:{"plain":""},on:{"click":function () { return _vm.resetBtn(); }}},[_vm._v("重 置")])],1),_c('el-button',{directives:[{name:"show",rawName:"v-show",value:(!this.$route.query.id),expression:"!this.$route.query.id"}],staticClass:"common-screen-btn btn-width common-noMarginLeft",attrs:{"type":"primary"},on:{"click":function($event){return _vm.addDrawer('add', '', '')}}},[_vm._v("新增类目")]),_c('el-table',{staticClass:"common-table",attrs:{"data":_vm.filelist}},[_c('el-table-column',{attrs:{"label":"文档名称","formatter":_vm.tableColumn},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.type == 1)?_c('img',{staticClass:"file-icons",attrs:{"src":require("@/assets/images/folder.svg")}}):_vm._e(),(scope.row.type == 1)?_c('el-button',{attrs:{"type":"text","size":"small"},on:{"click":function () { return _vm.seeMore(scope.row); }}},[_vm._v(_vm._s(scope.row.fileName))]):_c('common-picture',{attrs:{"fileUrl":scope.row.path,"fileType":_vm.Config.fileType.imgType.includes(
                  scope.row.path.split('.')[
                    scope.row.path.split('.').length - 1
                  ]
                )
                  ? 'imgText'
                  : 'file',"fileName":scope.row.fileName}})]}}])}),_c('el-table-column',{attrs:{"prop":"time","label":"上传文档时间","formatter":_vm.tableColumn}}),_c('el-table-column',{attrs:{"prop":"nickName","label":"创建人","formatter":_vm.tableColumn}}),_c('el-table-column',{attrs:{"prop":"platformName","label":"操作"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.type == 0)?_c('el-button',{attrs:{"type":"text","size":"mini"},on:{"click":function($event){return _vm.down(scope.row)}}},[_vm._v("下载 ")]):_vm._e()]}}])})],1)],1),_c('el-tab-pane',{attrs:{"label":"我的文档","name":"second"}},[_c('breadcrumb',{attrs:{"BreadcrumbData":_vm.BreadcrumbData},on:{"return":_vm.returnUrl}}),_c('div',{staticClass:"common-screen-container"},[_c('div',{staticClass:"common-input-container"},[_c('span',[_vm._v("文档名称/关键词:")]),_c('el-input',{staticClass:"common-screen-input",attrs:{"placeholder":"请输入内容","clearable":""},model:{value:(_vm.params.fileName),callback:function ($$v) {_vm.$set(_vm.params, "fileName", $$v)},expression:"params.fileName"}})],1),_c('div',{staticClass:"common-input-container"},[_c('common-date',{ref:"commonReset",on:{"commonGetDate":_vm.commonGetDate}})],1),_c('el-button',{staticClass:"common-screen-btn",attrs:{"type":"primary"},on:{"click":function () {
              _vm.getCloudList();
            }}},[_vm._v("查 询")]),_c('el-button',{staticClass:"common-screen-btn",attrs:{"plain":""},on:{"click":function () { return _vm.resetBtn(); }}},[_vm._v("重 置")])],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(this.$route.query.id),expression:"this.$route.query.id"}],staticClass:"uploadBtn-container common-noMarginLeft"},[_c('img',{staticClass:"upload-icon",attrs:{"src":require("@/assets/images/upload.svg")}}),_vm._v(" 上传 "),_c('input',{staticClass:"uploadBtn-input",attrs:{"type":"file","multiple":""},on:{"input":function (e) {
              _vm.uploadFiles(e, _vm.params.parentId);
            }}})]),_c('el-button',{directives:[{name:"show",rawName:"v-show",value:(!this.$route.query.id),expression:"!this.$route.query.id"}],staticClass:"common-screen-btn btn-width common-noMarginLeft",attrs:{"type":"primary"},on:{"click":function($event){return _vm.addDrawer('add', '', '')}}},[_vm._v("新增类目")]),_c('el-button',{directives:[{name:"show",rawName:"v-show",value:(this.$route.query.id),expression:"this.$route.query.id"}],staticClass:"common-screen-btn new-add btn-width",attrs:{"plain":""},on:{"click":function($event){return _vm.addDrawer('add', _vm.params.parentId, '')}}},[_vm._v("新增子类目")]),_c('el-table',{staticClass:"common-table",attrs:{"data":_vm.myfilelist}},[_c('el-table-column',{attrs:{"label":"文档名称","formatter":_vm.tableColumn},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.type == 1)?_c('img',{staticClass:"file-icons",attrs:{"src":require("@/assets/images/folder.svg")}}):_vm._e(),(scope.row.type == 1)?_c('el-button',{attrs:{"type":"text","size":"small"},on:{"click":function () { return _vm.seeMore(scope.row); }}},[_vm._v(_vm._s(scope.row.fileName))]):_c('common-picture',{attrs:{"fileUrl":scope.row.path,"fileType":_vm.Config.fileType.imgType.includes(
                  scope.row.path.split('.')[
                    scope.row.path.split('.').length - 1
                  ]
                )
                  ? 'imgText'
                  : 'file',"fileName":scope.row.fileName}})]}}])}),_c('el-table-column',{attrs:{"prop":"time","label":"上传文档时间","formatter":_vm.tableColumn}}),_c('el-table-column',{attrs:{"prop":"nickName","label":"创建人","formatter":_vm.tableColumn}}),_c('el-table-column',{attrs:{"label":"操作"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.type == 1 && scope.row.canEdit == 1)?_c('el-button',{attrs:{"type":"text","size":"mini"},on:{"click":function($event){return _vm.addDrawer('edit', scope.row.fileId, scope.row.fileName)}}},[_vm._v("编辑 ")]):_vm._e(),(scope.row.type == 1 && scope.row.canEdit == 0)?_c('el-button',{attrs:{"type":"text","size":"mini","disabled":""}},[_vm._v("编辑 ")]):_vm._e(),(scope.row.type == 0)?_c('el-button',{attrs:{"type":"text","size":"mini"},on:{"click":function($event){return _vm.down(scope.row)}}},[_vm._v("下载 ")]):_vm._e(),(scope.row.canEdit == 0)?_c('el-button',{attrs:{"type":"text","size":"mini","disabled":""}},[_vm._v("移动 ")]):_c('el-button',{attrs:{"type":"text","size":"mini"},on:{"click":function () {
                  _vm.getFolderList(scope.row);
                }}},[_vm._v("移动 ")]),(scope.row.type == 0)?_c('el-popconfirm',{staticClass:"popcon",attrs:{"title":"确定删除吗？"},on:{"confirm":function($event){return _vm.del(scope.row)}}},[_c('el-button',{attrs:{"slot":"reference","type":"text","size":"mini"},slot:"reference"},[_vm._v(" 删除 ")])],1):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":"是否公开"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return (scope.row.type == 0)?[_c('el-switch',{attrs:{"active-value":1,"inactive-value":0,"active-color":"#1890FF","inactive-color":"#DBDBDB"},on:{"change":function($event){return _vm.changeShow(scope.row)}},model:{value:(scope.row.showFlag),callback:function ($$v) {_vm.$set(scope.row, "showFlag", $$v)},expression:"scope.row.showFlag"}})]:undefined}}],null,true)})],1)],1)],1),_c('el-dialog',{directives:[{name:"dialogDrag",rawName:"v-dialogDrag"}],attrs:{"title":_vm.dialogTit,"visible":_vm.dialogVisible,"width":"30%","append-to-body":true,"before-close":function () {
        _vm.canleBtn();
      }},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('el-form',{ref:"form",attrs:{"model":_vm.form,"rules":_vm.formRules,"label-width":"110px"}},[_c('el-form-item',{attrs:{"label":"类目名称：","prop":"name"}},[_c('el-input',{model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.name"}})],1)],1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{staticClass:"common-screen-btn",on:{"click":_vm.canleBtn}},[_vm._v("取 消")]),_c('el-button',{staticClass:"common-screen-btn",attrs:{"loading":_vm.btnLoading,"type":"primary"},on:{"click":_vm.submitBtn}},[_vm._v("确 定")])],1)],1),_c('el-dialog',{directives:[{name:"dialogDrag",rawName:"v-dialogDrag"}],attrs:{"title":"移动到","visible":_vm.moveDialogVisible,"width":"30%","append-to-body":true},on:{"update:visible":function($event){_vm.moveDialogVisible=$event}}},[_c('el-tree',{attrs:{"data":_vm.data,"props":_vm.defaultProps,"accordion":""},on:{"node-click":_vm.handleNodeClick}}),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{staticClass:"common-screen-btn",on:{"click":function($event){_vm.moveDialogVisible = false}}},[_vm._v("取 消")]),_c('el-button',{staticClass:"common-screen-btn",attrs:{"loading":_vm.btnLoading,"type":"primary"},on:{"click":_vm.moveSubmitBtn}},[_vm._v("确 定")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }